/**
 * Sentry types are a pain to work with so this is a simple local partial copy. Take care in making
 * sure that any strings here line up with Sentry severity levels.
 */
export type SeverityLevel = 'debug' | 'error' | 'fatal' | 'warning';

/**
 * Fired when a trace event is dispatched for capturing.
 */
export type TraceCapturedEvent = CustomEvent<{
  /**
   * If the level is error or warning, then this is the error. Preferably an error object.
   *
   * If the level is debug/info/log, then this is the first argument
   */
  error: any;

  /**
   * Optional extra details about the error
   */
  extra?: Record<string, any>;

  /**
   * Optional severity level
   */
  level?: SeverityLevel;
}>;

declare global {
  interface WindowEventMap {
    'trace-captured': TraceCapturedEvent;
  }
}

/**
 * Trace an error at the error level.
 *
 * @param value
 * @param extra
 */
export function error(value: any, extra?: Record<string, any>) {
  dispatch(value, extra, 'error');
}

/**
 * Trace an error at the warning level.
 *
 * @param value an error value
 * @param extra additional information about the error
 */
export function warn(value: any, extra?: Record<string, any>) {
  dispatch(value, extra, 'warning');
}

/**
 * Send a log message as a trace event
 *
 * @param args the arguments to log
 */
export function log(...args: any[]) {
  dispatch(args, undefined, 'debug');
}

function dispatch(error: any, extra?: Record<string, any>, level?: SeverityLevel) {
  // This try/catch is to guarantee infallibility although it is slightly too paranoid.

  try {
    dispatchEvent(new CustomEvent('trace-captured', {
      cancelable: false,
      detail: {
        error,
        extra,
        level
      }
    }));
  } catch (error) {
    // completely ignore the traced error and the error in tracing the traced error
  }
}
