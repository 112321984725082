declare global {
  interface Lange {
    /**
     * The global L'ange event queue, also known as the data layer.
     *
     * Only append {@link Event} or {@link CustomEvent} or subclasses or this queue.
     *
     * If listening for error events globally, please do not use {@link onerror}, because the type
     * of its first parameter is `string`. Instead, always use {@link addEventListener} because the
     * type of its first parameter is {@link ErrorEvent}, a subclass of `Event`.
     *
     * The data layer solves a fundamental problem with loading multiple JavaScript files that need
     * to coordinate, along with files and events fired by Shopify and other vendor integrations.
     * There is routinely some race conditions involved where some code will fire an event and some
     * other code wants to handle that event, but it is unclear whether the code that wants to
     * handle the event subscribes in time, *before* the event is fired. Trying to delay the firing
     * of the event does not work. Trying to ensure all listeners are registered early does not
     * work. So, instead, there are a set of global listeners listening to misc. events immediately
     * within the HTML head, as the very first thing. Even before Shopify injected script runs.
     *
     * This way, when some code loads and wants to listen to an event but is not sure if it
     * registered in time, it can search this events array to see if the event has already fired. If
     * an event is in the array, then the code knows the event has already fired and can handle it.
     * If the event is not in the array, then the code knows that it can start listening for the
     * event and will eventually receive it.
     *
     * Instead of storing a bunch of "didTheThingLoad" booleans all over the code, prefer to use the
     * queue as a memory of events. A thing has loaded if its loading event is present in the queue.
     *
     * @example
     *   // check for whether the event already fired
     *   for (const event of lange.queue) {
     *      if (event.type === 'my-event') {
     *         // handle the event that already fired
     *         myListener(event);
     *      }
     *   }
     *
     *   // start listening for the event and then handle it once it arrives
     *   addEventListener('my-event', myListener);
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/error_event
     */
    queue?: Event[];
  }
}

type EventMaps = DocumentEventMap & WindowEventMap;

/**
 * A callback function that should generally accept an {@link Event} or {@link CustomEvent}.
 * However, the type is not enforced.
 */
export type ListenerFunction = (...args: any[])=> void;

/**
 * Helper for registering a single listener that handles the case of late registration.
 *
 * This will replay early events to a listener even when the listener was registered after the
 * events have already occurred, and then also register the listener for future events.
 *
 * This does not only replay the first earlier event, it replays all earlier events. Be careful when
 * using this to listen for an event that should only occur once.
 *
 * This uses a try/catch when replaying so as to ensure that each manual listener call for a past
 * event that throws does not prevent the replaying of other calls. As a result, this also means
 * that this function does not throw if any one replay listener call fails. Errors are instead just
 * logged as a side effect.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
 */
export function addRetroEventListener<K extends keyof EventMaps,
  F extends ((event?: EventMaps[K])=> void)>(type: K, listener: F,
  options?: AddEventListenerOptions, target?: EventTarget) {
  for (const event of lange.queue) {
    if (isType(event, type)) {
      try {
        listener(event);
      } catch (error) {
        console.error(error);
      }

      // If once, then we do not replay any future events and we do not listen for any future
      // events.
      if (options?.once) {
        return;
      }
    }
  }

  // not using default initialization of target in function params, instead explicitly check here,
  // works around possible issue in older safari versions

  // we are extra careful to not just relay options. relaying undefined as the third parameter is
  // risky in some older browsers that support the boolean useCapture parameter, some browsers or
  // third party polyfill logic might misinterpret undefined as explicitly setting useCapture to
  // false. things get really hairy here, and this exact rare situation has been a source of bugs in
  // the past, so we are just being extra paranoid, even though it might be overly defensive.

  if (target) {
    if (options) {
      target.addEventListener(type, <ListenerFunction>listener, options);
    } else {
      target.addEventListener(type, <ListenerFunction>listener);
    }
  } else if (options) {
    addEventListener(type, <ListenerFunction>listener, options);
  } else {
    addEventListener(type, <ListenerFunction>listener);
  }
}

function isType<K extends keyof EventMaps>(event: any, type: K): event is EventMaps[K] {
  return event?.type === type;
}
