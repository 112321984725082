/**
 * dispatch accepts a variable optional target. The target could be the window, or it could be the
 * document. We technically should infer which type to use based on that. However, as a quick fix,
 * we just merge the various maps together so that we have one big map of event type to target. This
 * way it does not matter whether the caller uses addEventListener on window or document, the event
 * can be found in either map.
 */
type EventMaps = DocumentEventMap & WindowEventMap;

/**
 * Creates and dispatches a custom event.
 *
 * The primary benefit is type safety. Using this method of dispatching events ensures that the
 * given event properties align with the given event type. Fortunately all the extra type safety
 * transpiles away and does not bloat the code.
 *
 * A secondary benefit is that there is less boilerplate involved when dispatching events.
 *
 * @param type the type of the event
 * @param detail the custom event details
 * @param target from where to emit the event, such as from an element, defaults to window
 */
export function dispatch<
  Key extends keyof EventMaps,
  T extends Record<string, any> = EventMaps[Key]
>(type: Key, detail?: T['detail'], target?: EventTarget) {
  const event = new CustomEvent<T['detail']>(type, { cancelable: false, detail });

  if (target) {
    target.dispatchEvent(event);
  } else {
    dispatchEvent(event);
  }
}
