import * as Cookie from '@integrabeauty/cookie';

function writeUnsafe(cookie: Partial<Cookie.Cookie>) {
  const merged: Partial<Cookie.Cookie> = {
    ...constructDefaultCookie(),
    ...cookie
  };

  Cookie.write(merged);
}

function removeUnsafe(cookie: Partial<Cookie.Cookie>) {
  const merged: Partial<Cookie.Cookie> = {
    ...constructDefaultCookie(),
    ...cookie
  };

  Cookie.remove(merged);
}

export function constructDefaultCookie() {
  const cookie: Partial<Cookie.Cookie> = {};

  if (location.hostname === 'langehair.com') {
    // Add a leading . to support subdomains.
    cookie.domain = '.langehair.com';
  } else {
    // Use whatever is the alternate domain (e.g. the actual Shopify shop domain).
    cookie.domain = location.hostname;
  }

  const thirtyDaysFromNow = new Date();
  thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);
  cookie.expires = thirtyDaysFromNow;

  cookie.path = '/';
  cookie.same_site = 'Lax';
  cookie.secure = true;

  return cookie;
}

/**
 * infallible write
 */
export function write(cookie: Partial<Cookie.Cookie>) {
  try {
    writeUnsafe(cookie);
  } catch (error) {
    console.log(error);
  }
}

/**
 * infallible remove
 */
export function remove(cookie: Partial<Cookie.Cookie>) {
  try {
    removeUnsafe(cookie);
  } catch (error) {
    console.log(error);
  }
}
