import '../../element/top-banner/index.js';
import { addRetroEventListener } from '../../lib/add-retro-event-listener.js';
import { dispatch } from '../../lib/event-dispatch.js';
import * as menu from '../../lib/menu.js';
import { observeDocumentBodyReady } from './document-body-observer.js';

/**
 * Fired when a user clicks on the shroud overlay.
 */
type ShroudOverlayClickedEvent = CustomEvent<null>;

declare global {
  interface WindowEventMap {
    'shroud-overlay-clicked': ShroudOverlayClickedEvent;
  }
}

function onDocumentBodyReady(_event: WindowEventMap['document-body-ready']) {
  removeEventListener('document-body-ready', onDocumentBodyReady);

  const shroudOverlay = document.getElementById('shroud-overlay');
  shroudOverlay?.addEventListener('click', onShroudOverlayClick);

  const headerLinkShop = document.getElementById('header-link-shop');
  headerLinkShop?.addEventListener('click', onHeaderLinkShopClick);
}

function onShroudOverlayClick(_event: MouseEvent) {
  if (menu.isAnyOpen()) {
    menu.closeAnyOpen();
  }

  dispatch('shroud-overlay-clicked');
}

function onHeaderLinkShopClick(_event: MouseEvent) {
  if (menu.isOpen('shop-menu')) {
    menu.close('shop-menu');
  } else {
    menu.open('shop-menu');
  }
}

/**
 * There is some wacky init logic in this script because for some reason this script in particular
 * experiences a very strange error in the Instagram in app browser frequently enough that it
 * demands attention.
 */
function onDOMContentLoadedInit(_event: Event) {
  // We want to avoid multiple invocations of the listener in this case because it could break the
  // site. We have observed wacky behavior on some devices where events trigger more than once.
  removeEventListener('DOMContentLoaded', onDOMContentLoadedInit);

  addEventListener('keydown', onKeydown);

  // Instead of assuming document.body exists, wait for it to exist. This should work around an
  // incredibly strange issue with DOMContentLoaded and document.body in the Instagram in app
  // browser while having almost no impact on any one else as previous semantics are maintained by
  // the fact that document.body is checked immediately within the current tick in the observer
  // implementation.
  //
  // This does not guarantee that document.body is found. We will still see an error when all of the
  // checks for document.body fail because the event is always fired.

  // Ordering is important here. We have to first register before observe because observe runs
  // in the same tick on the first attempt, giving no time for the listener to be notified.

  addEventListener('document-body-ready', onDocumentBodyReady);
  observeDocumentBodyReady(100, 100);
}

/**
 * The section is initially hidden because it avoids a flash of content issue with the delayed
 * loading of the style that hides it. Here we unhide it. We have to do this via javascript because
 * of limitations of shopify sections.
 */
function onDOMContentLoadedShowSideMenu() {
  const sideMenu = document.getElementById('shopify-section-side-menu');
  sideMenu?.classList.remove('hidden');
}

function onKeydown(event: KeyboardEvent) {
  if (event.defaultPrevented) {
    return;
  }

  const key = event.code || event.key;
  if (key === 'Escape' || key === 'Esc') {
    if (menu.isAnyOpen()) {
      menu.closeAnyOpen();
    }
  }
}

addRetroEventListener('DOMContentLoaded', onDOMContentLoadedInit);
addRetroEventListener('DOMContentLoaded', onDOMContentLoadedShowSideMenu);
