export function writeEmail(value: string) {
  try {
    localStorage.setItem('lange_attentive_email', value);
  } catch (error) {
    console.warn(error);
  }
}

export function readEmail() {
  try {
    return localStorage.getItem('lange_attentive_email');
  } catch (error) {
    console.warn(error);
  }
}

export function writePhone(value: string) {
  try {
    localStorage.setItem('lange_attentive_phone', value);
  } catch (error) {
    console.warn(error);
  }
}

export function readPhone() {
  try {
    return localStorage.getItem('lange_attentive_phone');
  } catch (error) {
    console.warn(error);
  }
}

/**
 * Utility method leveraging undocumented functionality from the injected Attentive library. The
 * methods should be used to hide and show any injected Attentive modals such as the the "Spin to
 * Win" CTA located in the bottom left corner of the website or the "Spin to Win" modal presented on
 * first visit.
 *
 * Currently we are using these methods to hide all Attentive injected content when showing the side
 * menu or cart drawer and showing theme again once they are closed.
 */
export function hideModalIfActive() {
  if (window.__attentive?.invoked && __attentive.hide) {
    __attentive.hide();
  }
}

export function showModalIfActive() {
  if (window.__attentive?.invoked && __attentive.show) {
    __attentive.show();
  }
}
