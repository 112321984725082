import { label } from '../../lib/a11y.js';
import { addRetroEventListener } from '../../lib/add-retro-event-listener.js';
import * as cartDrawer from '../../lib/cart-drawer.js';
import type { CustomElement } from '../../lib/custom-element.js';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders the cart icon with a count of items added to cart. Emits an event when clicked.
 */
class CartCounterIcon extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return ['data-quantity'];
  }

  readonly dataset!: {
    quantity: string;
  };

  public shadowRoot!: ShadowRoot;
  private onButtonClickBound = onButtonClick.bind(this);
  private onCartUpdatedBound = this.onCartUpdated.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    const cartCounterButton = this.shadowRoot.querySelector('button');
    cartCounterButton.addEventListener('click', this.onButtonClickBound);

    addRetroEventListener('cart-updated', this.onCartUpdatedBound);

    this.render();
  }

  public disconnectedCallback() {
    const cartCounterButton = this.shadowRoot.querySelector('button');
    cartCounterButton?.removeEventListener('click', this.onButtonClickBound);

    removeEventListener('cart-updated', this.onCartUpdatedBound);
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (this.isConnected && oldValue !== newValue) {
      this.render();
    }
  }

  private render() {
    const cartCounter = this.shadowRoot.getElementById('cart-counter');
    cartCounter.textContent = this.dataset.quantity;

    const quantity = parseInt(this.dataset.quantity, 10);
    let itemsText = '';
    if (quantity === 0) {
      cartCounter.classList.add('hidden');
      cartCounter.removeAttribute('aria-label');
    } else {
      cartCounter.classList.remove('hidden');
      if (quantity === 1) {
        itemsText = 'one item added';
      } else {
        itemsText = quantity.toString() + ' items added';
      }
    }

    const button = this.shadowRoot.querySelector('button');
    label(button, 'View shopping cart ' + itemsText);
  }

  private onCartUpdated(event: WindowEventMap['cart-updated']) {
    this.dataset.quantity = event.detail.cart.item_count.toString();
  }
}

function onButtonClick(_event: MouseEvent) {
  cartDrawer.open();
}

declare global {
  interface HTMLElementTagNameMap {
    'cart-counter-icon': CartCounterIcon;
  }
}

if (!customElements.get('cart-counter-icon')) {
  customElements.define('cart-counter-icon', CartCounterIcon);
}
