/**
 * Collapses an element by updating its aria properties.
 *
 * This tolerates null/undefined as input.
 *
 * @todo investigate whether it makes more sense to remove the attribute in this case considering
 * that the default is open?
 */
export function collapse(element: Element) {
  // We use setAttribute because we have observed errors using other techniques.

  element?.setAttribute('aria-expanded', 'false');
}

/**
 * Expands an element by updating its aria properties.
 *
 * This tolerates null/undefined as input.
 */
export function expand(element: Element) {
  // We use setAttribute because we have observed errors using other techniques.

  element?.setAttribute('aria-expanded', 'true');
}

export function disable(element: Element) {
  element?.setAttribute('aria-disabled', 'true');
}

/**
 * @todo remove the attribute instead? what is better? to set to false or remove?
 */
export function enable(element: Element) {
  element?.setAttribute('aria-disabled', 'false');
}

/**
 * @param element the element to mark as hidden, may be null or undefined
 */
export function hide(element: Element) {
  element?.setAttribute('aria-hidden', 'true');
}

/**
 * @param element the element to mark as visible, may be null or undefined
 * @param remove if true then the aria-hidden attribute is removed, if false then the aria hidden
 * attribute is set to the string value of false, this is optional and the default behavior is to
 * keep the attribute and set it to false
 *
 * @todo should we just unconditionally always remove instead of set to hidden? it is tricky because
 * we have some poorly coded css that is checking for aria-hidden=false instead of !=true, we might
 * have to first go and fix the css
 */
export function show(element: Element, remove = false) {
  if (remove) {
    element?.removeAttribute('aria-hidden');
  } else {
    element?.setAttribute('aria-hidden', 'false');
  }
}

/**
 * Mark the element and its descendants as inert. This is similar to "aria-hidden"
 * but additionally helps with keyboard focus, as the element would never receive it.
 * Marking the element as inert changes its state not only in the accessibility tree
 * but also in the render tree. The element will not receive any events.
 *
 * @param element any HTML element
 */
export function makeInert(element: HTMLElement) {
  element.setAttribute('inert', '');
}

/**
 * Clear the inert attribute and state.
 *
 * @param element any HTML element
 */
export function clearInert(element: HTMLElement) {
  element.removeAttribute('inert');
}

/**
 * @param element any html element, may be null/undefined
 * @returns whether the given element is expanded
 */
export function isExpanded(element: Element) {
  return element?.getAttribute('aria-expanded') === 'true';
}

/**
 * @param element an input element that can be checked/unchecked
 */
export function check(element: HTMLElement) {
  element.setAttribute('aria-checked', 'true');
}

/**
 * @param element an input element that can be checked/unchecked
 */
export function uncheck(element: HTMLElement) {
  element.setAttribute('aria-checked', 'false');
}

/**
 * @param element any HTML element used as a selectable option
 */
export function select(element: HTMLElement) {
  element.setAttribute('aria-selected', 'true');
}

/**
 * @param element any HTML element used as a selectable option
 */
export function unselect(element: HTMLElement) {
  element.removeAttribute('aria-selected');
}

/**
 * @param element any HTML element
 * @param label string used as a value for the added `aria-label` attribute
 */
export function label(element: HTMLElement, label: string) {
  element.setAttribute('aria-label', label);
}

/**
 * Remove the `aria-label` attribute from the element
 *
 * @param element any HTML element
 */
export function removeLabel(element: HTMLElement) {
  element.removeAttribute('aria-label');
}

/**
 * Set `aria-current` attribute to the provided element.
 *
 * @param element any HTML element
 * @param value one of available values
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-current#values}
 * @see List of all available values
 */
export function current(element: HTMLElement, value: AriaCurrentValues) {
  // TODO should we use optional chaining?
  element.setAttribute('aria-current', value);
}

type AriaCurrentValues = 'date' | 'false' | 'location' | 'page' | 'step' | 'time' | 'true';

/**
 * Define if the element should receive keyboard focus
 *
 * @param element any HTML element
 * @param value true - if element should be focusable
 *              false - if it shouldn't
 *              undefined - if the default browser behavior should be restored
 */
export function focusable(element: HTMLElement, value: boolean | undefined) {
  if (element) {
    if (typeof value === 'undefined') {
      element.removeAttribute('tabindex');
    } else {
      element.setAttribute('tabindex', value ? '0' : '-1');
    }
  }
}

/**
 * Set `aria-controls` attribute to the provided element.
 *
 * @param element any HTML element
 * @param value {string} ID of the controlled element
 */
export function controls(element: HTMLElement, value: string) {
  element.setAttribute('aria-controls', value);
}
