import { Route, routes } from './routes.js';
import * as trace from './trace.js';

declare global {
  interface Lange {
    lazy?: typeof load;

    /**
     * This global is "private" to this module, do not use it directly.
     *
     * To append a route from global scope (e.g. in liquid), use `lange.lazy()`.
     */
    routes?: Route[];
  }
}

/**
 * Records which routes have been requested.
 */
const history: Record<string, any> = {};

function isValidRoute(value: any) {
  return typeof value === 'string' && value.length > 0;
}

/**
 * Triggers the dynamic import of a given route.
 *
 * Webpack works better when using dynamic imports that use a static path. As such, this looks up
 * modules by name in a map that contains a function that contains a dynamic import with a static
 * path instead of just using `import(pathVariable)`.
 *
 * This function is written so as to ensure that the failure to load any given module does not
 * prevent other modules from loading.
 *
 * This suppresses import errors. Import errors are logged as a side effect.
 *
 * @todo consider adding retries to handler
 */
export function load(route: Route) {
  if (!isValidRoute(route)) {
    console.warn('Invalid route', route);
    return;
  }

  if (route in history) {
    console.warn('Duplicate route', route);
    return;
  }

  history[route] = 1;

  const handler = routes[route];
  if (!handler) {
    console.warn('Unknown route', route);
    return;
  }

  handler().catch(trace.warn);
}

export function init() {
  window.lange = window.lange || {};

  // On module load, load any modules that called lange.lazy before this module loaded.

  for (const route of lange.routes || []) {
    load(route);
  }

  // Overwrite lange.lazy such that all future calls to lange.lazy bypass the queue and are
  // immediately loaded.

  lange.lazy = load;

  // To protect against any kind of duplicate calls, clear routes.

  // In the nonsense situation where this runs prior to the inline script that initializes the
  // array, ensure the array is initialized and empty. It should not matter because we already
  // replaced lange.lazy.

  lange.routes = [];
}
