/**
 * Never interact with the shroud elements directly. Always use this library.
 */
export function show() {
  disallowTouchMove();
  document.documentElement.classList.add('js-no-scroll');
  document.getElementById('main-content')?.classList.add('MainContent--shroud');
  document.getElementById('shroud-overlay')?.classList.add('Shroud--active');
}

/**
 * Never interact with the shroud elements directly. Always use this library.
 */
export function hide() {
  allowTouchMove();
  document.documentElement.classList.remove('js-no-scroll');
  document.getElementById('main-content')?.classList.remove('MainContent--shroud');
  document.getElementById('shroud-overlay')?.classList.remove('Shroud--active');
}

const isIos = /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

function onTouchMove(event: TouchEvent) {
  event.preventDefault();
}

/**
 * Enable "scroll lock" by disabling touch move events.
 */
function disallowTouchMove() {
  if (isIos) {
    document.body.addEventListener('touchmove', onTouchMove);
  }
}

/**
 * Disable "scroll lock" by re-enabling touch move events.
 */
function allowTouchMove() {
  if (isIos) {
    document.body.removeEventListener('touchmove', onTouchMove);
  }
}
