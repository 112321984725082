/**
 * When Webpack is configured to use raw loader for scss files, Webpack generates javascript esm
 * modules that contain a default exported string containing some css. That module can be used in
 * combination with this helper function to append the css text to the dom.
 *
 * This is safe to call before the dom has loaded.
 */
export function appendStyleModule(mod: typeof import('*.scss')) {
  appendCSSText(mod.default);
}

/**
 * Generates a style element containing the given content and appends it to the dom.
 *
 * This is an alternative to the webpack style-loader that circumvents the overly complex webpack
 * configuration and plugin behavior. This intentionally avoids using CSSStyleSheet or any other
 * kind of feature that is not broadly supported or accurately polyfilled.
 *
 * This is safe to call before the dom has loaded.
 *
 * This is safe to call on platforms where document.head is not defined.
 *
 * This annotates appended style attributes with a data-lazy attribute so that it is trivial to
 * identify which elements were appended to the dom in this manner. This extra annotation is not
 * expected to have a material performance impact.
 */
export function appendCSSText(content: string) {
  const style = document.createElement('style');
  style.dataset.lazy = 'true';
  const node = document.createTextNode(content);
  style.appendChild(node);
  const parent = document.head || document.body || document.documentElement;
  parent.appendChild(style);
}
